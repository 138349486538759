<template>
  <CCard>
    <CCardHeader>
      <CIcon name="cil-input-power" />
      Edytuj przewoźnika
    </CCardHeader>
    <CCardBody>
      <CRow>
        <CCol sm="12">
          <CForm>
            <CRow>
              <CCol md="12">
                <CInput
                  :label="$i18n.translate('forms.transl74')"
                  v-model.trim="$v.form.name.$model"
                  plaintext
                />
                <CInput
                  :label="$i18n.translate('forms.transl75')"
                  v-model.trim="$v.form.surname.$model"
                  plaintext
                />
                <CInput
                  :label="$i18n.translate('forms.transl76')"
                  v-model.trim="$v.form.email.$model"
                  plaintext
                />
                <CInput
                  label="IP:"
                  v-model.trim="$v.form.ip.$model"
                  plaintext
                />
                <CInput
                  label="User Agent:"
                  v-model.trim="$v.form.user_agent.$model"
                  plaintext
                />
                <CInput
                  :label="$i18n.translate('forms.transl77')"
                  v-model.trim="$v.form.description.$model"
                  plaintext
                />
                <CInput
                  :label="$i18n.translate('forms.transl78')"
                  v-model.trim="$v.form.created_at.$model"
                  plaintext
                />
              </CCol>
            </CRow>


            <CButton
  class="ml-1"
  color="success"
  @click="goBack"
>
  {{ $i18n.translate('forms.transl53') }}
</CButton>

          </CForm>
          <br />
        </CCol>
      </CRow>
    </CCardBody >
  </CCard >
</template >
<style>
  label{
    font-weight: bold;
  }
</style>
<script>
import axios from 'axios';
import Vue from 'vue';
import CKEditor from 'ckeditor4-vue';
import Swal from "sweetalert2";
import {validationMixin} from "vuelidate"
import {required, minLength, email, sameAs, helpers} from "vuelidate/lib/validators"

export default {
  data() {
    return {
      form: {
        name: null,
        surname: null,
        email: null,
        ip: null,
        user_agent: null,
        description: null,
        created_at: null,
      },
    }
  },
  computed: {
    formString() {
      return JSON.stringify(this.form, null, 4)
    },
    isValid() {
      return !this.$v.form.$invalid
    },
    isDirty() {
      return this.$v.form.$anyDirty
    },
  },
  mixins: [validationMixin],
  validations: {
    form: {
      name: {
      },
      surname: {
      },
      email: {
      },
      ip: {
      },
      user_agent: {
      },
      description: {
      },
      created_at: {
      },
    }
  },
  methods: {
    goBack() {
      this.$router.replace({ path: '/browsing-history' })
    },
    checkIfValid(fieldName) {
      const field = this.$v.form[fieldName]
      if (!field.$dirty) {
        return null
      }
      return !(field.$invalid || field.$model === '')
    },

    getEmptyForm() {
      return {
        name: null,
        surname: null,
        email: null,
        ip: null,
        user_agent: null,
        description: null,
        created_at: null
      }
    }
  },
  created: function () {
    let self = this;
    axios.get(this.$apiAdress + '/api/browsing-history/' + self.$route.params.id + '/edit?token=' + localStorage.getItem("api_token"))
      .then(function (response) {
        self.form.name= response.data.record.user.name;
        self.form.surname= response.data.record.user.surname;
        self.form.email= response.data.record.user.email;
        self.form.ip= response.data.record.ip;
        self.form.user_agent= response.data.record.user_agent;
        self.form.description= response.data.record.description;
        self.form.created_at= response.data.record.created_at;
      }).catch(function (error) {
      console.log(error);
      self.$router.push({path: '/login'});
    });
  }
}
</script>
